import { Session as OrySession } from "@ory/client";
import axios from "axios";
import Head from "next/head";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { FullScreenLoader } from "~/components/full-screen-loader";
import { useOry } from "~/hooks/use-ory";
import {
  analytics,
  ButtonClickEvent,
  ScreenShownEvent,
} from "~/libs/analytics";
import { urlForPath } from "~/libs/http";
import { Box, Button, Heading, Text } from "~/ui/components";

import { AuthLayout } from "./auth-layout";

type ProfileProps = {
  session: OrySession;
  onLogout: () => void;
  onUpdateSettings: () => void;
  onAccountDelete: () => void;
};

type UnAuthenticatedProps = {
  onLogin: () => void;
};

const Session = () => {
  const router = useRouter();
  const ory = useOry();

  const [session, setSession] = useState<OrySession | false | null>(null);
  const [logoutUrl, setLogoutUrl] = useState("#");

  const handleLogout = () => {
    analytics.track(
      new ButtonClickEvent({
        button: "logout",
        screen: "session",
      }),
    );

    router.push(logoutUrl);
  };

  const handleLogin = () => {
    analytics.track(
      new ButtonClickEvent({
        button: "login",
        screen: "session",
      }),
    );

    router.push("/");
  };

  const handleUpdateSetting = () => {
    analytics.track(
      new ButtonClickEvent({
        button: "update settings",
        screen: "session",
      }),
    );

    router.push("/settings");
  };

  const handleAccountDelete = () => {
    router.push("/delete");
  };

  useEffect(() => {
    const logoutFlow = async () => {
      try {
        const { data } = await ory.createBrowserLogoutFlow({
          returnTo: urlForPath("/"),
        });

        setLogoutUrl(data.logout_url);
      } catch (error) {
        if (!axios.isAxiosError(error)) throw error;

        switch (error.response?.status) {
          case 401:
            setLogoutUrl("#");
            break;
        }
      }
    };

    logoutFlow();
  });

  useEffect(() => {
    const sessionFlow = async () => {
      try {
        const { data } = await ory.toSession();
        setSession(data);
      } catch (error) {
        if (!axios.isAxiosError(error)) throw error;

        switch (error.response?.status) {
          case 401:
            setSession(false);
            break;
        }
      }
    };

    sessionFlow();
  }, [ory, router]);

  const screenShown = useCallback(() => {
    analytics.track(
      new ScreenShownEvent({
        screen: "session",
      }),
    );
  }, []);

  useEffect(() => screenShown(), [screenShown]);

  if (!session && session !== false) {
    return <FullScreenLoader />;
  }

  return (
    <>
      <Head>
        <title>Session Page</title>
      </Head>
      <AuthLayout>
        <Container>
          {session && (
            <Profile
              session={session}
              onLogout={handleLogout}
              onAccountDelete={handleAccountDelete}
              onUpdateSettings={handleUpdateSetting}
            />
          )}
          {!session && <UnAuthenticated onLogin={handleLogin} />}
        </Container>
      </AuthLayout>
    </>
  );
};

const Profile = ({
  session,
  onLogout,
  onUpdateSettings,
  onAccountDelete,
}: ProfileProps) => {
  const { t } = useTranslation();
  const { traits } = session.identity!;
  const [busy, busySet] = useState(false);

  const toggleSubmitting = (fn: any) => {
    try {
      busySet(true);
      fn();
    } finally {
      busySet(false);
    }
  };

  const onLogout_ = useCallback(() => {
    toggleSubmitting(onLogout);
  }, [onLogout]);

  const onUpdateSettings_ = useCallback(() => {
    toggleSubmitting(onUpdateSettings);
  }, [onUpdateSettings]);

  const onAccountDelete_ = useCallback(() => {
    toggleSubmitting(onAccountDelete);
  }, [onAccountDelete]);

  return (
    <Box space="large">
      <Box space="small">
        <Heading level="h1">
          {t("common.hi", {
            name: traits?.name?.first || traits?.email || "Welt",
          })}
        </Heading>
        <Text variant="light">{traits?.email}</Text>
      </Box>
      <Box space="large">
        <Box space="small">
          <Button fullWidth variant="light" onClick={onUpdateSettings_}>
            {t("common.changePassword")}
          </Button>
          <Button fullWidth variant="light" onClick={onAccountDelete_}>
            {t("common.accountDelete")}
          </Button>
        </Box>

        <Box space="small">
          <Button fullWidth loading={busy} onClick={onLogout_}>
            {t("common.logout")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const UnAuthenticated = ({ onLogin }: UnAuthenticatedProps) => {
  const { t } = useTranslation();

  return (
    <Button fullWidth onClick={onLogin}>
      {t("common.login")}
    </Button>
  );
};

const Container = styled.div`
  text-align: center;
`;

export { Session };
