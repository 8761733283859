/* eslint-disable react-hooks/exhaustive-deps */
import { SettingsFlow } from "@ory/client";
import Head from "next/head";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { AuthLayout } from "~/features/auth";
import { analytics, ButtonClickEvent, trackFlow } from "~/libs/analytics";
import { setFormErrors, setFormValues } from "~/libs/ory";
import {
  Box,
  Button,
  Error,
  FormField,
  Heading,
  InputField,
} from "~/ui/components";
import { FormIntro } from "~/ui/components/form-intro";

import { FlowMessage } from "./flow-message";

type FormData = {
  csrf_token: string;
  password: string;
  repeatPassword: string;
};

type UpdatePasswordProps = {
  flow?: SettingsFlow;
  updateInProgress: boolean;
  source?: string;
  onSubmit: ({
    csrfToken,
    password,
  }: {
    csrfToken: string;
    password: string;
  }) => Promise<void>;
};

const UpdatePassword = (props: UpdatePasswordProps) => {
  const { t } = useTranslation();
  const form = useForm<FormData>({ mode: "onBlur" });

  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors, touchedFields },
  } = form;

  const validatePassword = useCallback(
    (value: string) => {
      const password = getValues("password");
      return (
        value === password ||
        t("register.validation.repeatPassword.match").toString()
      );
    },
    [t],
  );

  const trackUpdatePasswordFlow = (props: UpdatePasswordProps) => {
    switch (props.source) {
      case "forgot-password":
        trackFlow({ flow: props.flow, screen: "pw-reset: set new password" });
        break;

      case "recovery":
        trackFlow({
          flow: props.flow,
          screen: "user-migration: set new password",
        });
        break;

      default:
        trackFlow({ flow: props.flow, screen: "settings" });
    }
  };

  const trackUpdatePassword = (source?: string) => {
    switch (source) {
      case "forgot-password":
        analytics.track(
          new ButtonClickEvent({
            button: "set new password",
            screen: "pw-reset: set new password",
          }),
        );
        break;

      case "recovery":
        analytics.track(
          new ButtonClickEvent({
            button: "set new password",
            screen: "user-migration: set new password",
          }),
        );
        break;

      default:
        analytics.track(
          new ButtonClickEvent({
            button: "set new password",
            screen: "settings",
          }),
        );
    }
  };

  const onSubmit = async (data: FormData) => {
    trackUpdatePassword(props.source);

    await props.onSubmit({
      csrfToken: data.csrf_token,
      password: data.password,
    });
  };

  useEffect(() => {
    if (!props.flow) return;

    setFormValues(form, props.flow);
    setFormErrors(form, props.flow);
    trackUpdatePasswordFlow(props);
  }, [props.flow]);

  return (
    <>
      <Head>
        <title>{t("updatePassword.title")}</title>
      </Head>
      <AuthLayout>
        <form onSubmit={handleSubmit(onSubmit)}>
          <input type="hidden" {...register("csrf_token")} />

          <Box space="large">
            <FormIntro title={t("updatePassword.heading")} />

            <FlowMessage flow={props.flow}></FlowMessage>

            <Box space="medium">
              <FormField>
                <InputField
                  required
                  aria-required
                  autoComplete="new-password"
                  type="password"
                  label={`${t<string>("common.password")}`}
                  check={touchedFields?.password && !errors?.password}
                  aria-invalid={!!errors?.password}
                  exclaim={!!errors?.password}
                  {...register("password", {
                    required: t(
                      `register.validation.password.required`,
                    ).toString(),
                    minLength: {
                      value: 8,
                      message: t(`register.validation.password.minLength`, {
                        minLength: 8,
                      }),
                    },
                  })}
                />
                {errors.password && (
                  <Error>
                    {errors.password.message || errors.password.type}
                  </Error>
                )}
              </FormField>
              <FormField>
                <InputField
                  required
                  aria-required
                  autoComplete="new-password"
                  type="password"
                  label={`${t<string>("common.passwordRepeat")}`}
                  check={
                    touchedFields?.repeatPassword && !errors?.repeatPassword
                  }
                  aria-invalid={!!errors?.repeatPassword}
                  exclaim={!!errors?.repeatPassword}
                  {...register("repeatPassword", {
                    required: t(
                      `register.validation.repeatPassword.required`,
                    ).toString(),
                    validate: {
                      match: validatePassword,
                    },
                    deps: ["password"],
                  })}
                />
                {errors.repeatPassword && (
                  <Error>
                    {errors.repeatPassword.message ||
                      errors.repeatPassword.type}
                  </Error>
                )}
              </FormField>

              <Button loading={props.updateInProgress}>
                {t("updatePassword.saveAndContinue")}
              </Button>
            </Box>
          </Box>
        </form>
      </AuthLayout>
    </>
  );
};

export { UpdatePassword };
