import Link from "next/link";
import { useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  analytics,
  ScreenShownEvent,
  ButtonClickEvent,
} from "~/libs/analytics";
import {
  Box,
  Button,
  Error,
  FormField,
  Heading,
  InputField,
  Text,
} from "~/ui/components";
import { FormIntro } from "~/ui/components/form-intro";

interface Props {
  onSubmit: any; // TODO:
}

const trackForgotPassword = () => {
  analytics.track(
    new ButtonClickEvent({
      button: "forgot password",
      screen: "login: password input",
    }),
  );
};

const InputPassword = ({ onSubmit }: Props) => {
  const form = useFormContext();
  const { t } = useTranslation();
  const [loading, loadingSet] = useState(false);

  const onSubmit_ = useCallback(
    async (data: any) => {
      try {
        loadingSet(true);
        await onSubmit(data);
      } finally {
        loadingSet(false);
      }
    },
    [onSubmit],
  );

  const {
    getValues,
    register,
    handleSubmit,
    formState: { errors, touchedFields },
  } = form;

  const screenShown = useCallback(() => {
    analytics.track(
      new ScreenShownEvent({
        screen: "login: password input",
      }),
    );
  }, []);

  useEffect(() => screenShown(), [screenShown]);

  return (
    <form onSubmit={handleSubmit(onSubmit_)}>
      <input type="hidden" {...register("csrf_token")} />
      <input type="hidden" {...register("method")} />
      <input type="hidden" {...register("identifier")} />

      <Box space="large" textCenter>
        <FormIntro
          title={t("login.password.heading")}
          intro={t("login.password.introductory")}
        />
        <Box space="medium">
          <FormField>
            <InputField
              required
              aria-required
              autoFocus
              autoComplete="current-password"
              type="password"
              label={`${t<string>("common.password")}`}
              check={touchedFields?.password && !errors?.password}
              aria-invalid={!!errors?.password}
              exclaim={!!errors?.password}
              {...register("password", {
                required: t(`register.validation.password.required`).toString(),
              })}
            />
            {errors?.password && (
              <Error>{errors.password.message?.toString()}</Error>
            )}
          </FormField>
          <Button loading={loading}>{t("common.continue")}</Button>
          <Link
            onClick={trackForgotPassword}
            href={`/forgot-password?identifier=${getValues("identifier")}`}>
            <Text as="span" variant="primary">
              {t("common.forgotPassword")}?
            </Text>
          </Link>
        </Box>
      </Box>
    </form>
  );
};

export { InputPassword };
