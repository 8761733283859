/* eslint-disable react-hooks/exhaustive-deps */
import { SettingsFlow } from "@ory/client";
import axios from "axios";
import { useRouter } from "next/router";
import { useEffect, useState, useCallback } from "react";

import { FullScreenLoader } from "~/components/full-screen-loader";
import { useOry } from "~/hooks/use-ory";
import { analytics, ScreenShownEvent, trackFlow } from "~/libs/analytics";
import { urlForPath } from "~/libs/http";
import { handleFlowError, setUriFlow } from "~/libs/ory";

import { UpdatePassword } from "./components/update-password";

const Settings = () => {
  const router = useRouter();
  const ory = useOry();
  const [flow, setFlow] = useState<SettingsFlow>();
  const [updateSettingsInProgress, setUpdateSettingsInProgress] =
    useState(false);

  const { flow: flowId, source, return_to: returnTo } = router.query;

  const onUpdatePassword = async ({
    csrfToken,
    password,
  }: {
    csrfToken: string;
    password: string;
  }) => {
    if (!flow?.id) return;

    await setUriFlow(router, flow.id);

    try {
      setUpdateSettingsInProgress(true);

      const { data } = await ory.updateSettingsFlow({
        flow: flow.id,
        updateSettingsFlowBody: {
          csrf_token: csrfToken,
          method: "password",
          password,
        },
      });

      setFlow(data);
      trackFlow({ flow: data, screen: "settings" });

      if (data.state === "success") {
        router.push(data.return_to || urlForPath("/api/redirect"));
      }
    } catch (error) {
      if (!axios.isAxiosError(error)) throw error;
      trackFlow({ flow: error.response?.data, screen: "settings" });

      await handleFlowError(router, "settings")(error);

      if (error.response?.status === 400) {
        setFlow(error.response.data);
      }
    } finally {
      setUpdateSettingsInProgress(false);
    }
  };

  useEffect(() => {
    const settingsFlow = async () => {
      if (!router.isReady || flow) {
        return;
      }

      if (flowId) {
        try {
          const { data } = await ory.getSettingsFlow({ id: String(flowId) });
          setFlow(data);

          trackFlow({ flow: data, screen: "settings" });
        } catch (error) {
          if (!axios.isAxiosError(error)) throw error;
          await handleFlowError(router, "settings")(error);
        }
        return;
      }

      try {
        const { data } = await ory.createBrowserSettingsFlow({
          ...(returnTo && { returnTo: String(returnTo) })
        });

        setFlow(data);
        trackFlow({ flow: data, screen: "settings" });
      } catch (error) {
        if (!axios.isAxiosError(error)) throw error;
        await handleFlowError(router, "settings")(error);
      }
    };

    settingsFlow();
  }, [router, router.isReady, flow, flowId]);

  const screenShown = useCallback(() => {
    switch (source) {
      case "forgot-password":
        analytics.track(
          new ScreenShownEvent({
            screen: "pw-reset: set new password",
          }),
        );
        break;

      case "recovery":
        analytics.track(
          new ScreenShownEvent({
            screen: "user-migration: set new password",
          }),
        );
        break;

      default:
        analytics.track(
          new ScreenShownEvent({
            screen: "settings",
          }),
        );
    }
  }, [source]);

  useEffect(() => screenShown(), [screenShown]);

  if (!flow) {
    return <FullScreenLoader />;
  }

  return (
    <UpdatePassword
      flow={flow}
      onSubmit={onUpdatePassword}
      source={String(source)}
      updateInProgress={updateSettingsInProgress}
    />
  );
};

export { Settings };
