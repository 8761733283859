import { useRouter } from "next/router";
import styled from "styled-components";
import { usePlanturaStore } from "~/stores/plantura";

import { Alert, Box, Card } from "~/ui/components";
import Footer from "~/ui/components/footer";
import Logo from "~/ui/components/logo";

interface Props {
  children: React.ReactNode;
}

const AuthLayout = ({ children }: Props) => {
  const router = useRouter();
  const { error } = router.query;

  const client = usePlanturaStore((state) => state.getClient());
  const isShopClient = client!.startsWith("shop") || false;

  return (
    <Wrapper>
      <Logo />
      <Container>
        <Box>
          <Card className="auth-content__container">
            {typeof error === "string" && (
              <Box>
                <Alert text={error} />
              </Box>
            )}
            {children}
          </Card>
        </Box>
      </Container>
      {isShopClient && <Footer style={{ marginBlockStart: "2rem" }} />}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 480px) {
    height: 100vh;
  }
`;

const Container = styled.div`
  align-self: center;
  width: 368px;
  margin-block-start: 1rem;
  margin-block-end: 1rem;

  @media (max-width: 480px) {
    width: 100%;
  }
`;

export { AuthLayout };
