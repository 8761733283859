import { palette } from "@plantura-garden/palette";
import Link from "next/link";
import styled from "styled-components";

interface Props extends React.ComponentPropsWithRef<"footer"> {}

const Footer = (props: Props) => {
  return (
    <StyledFooter {...props} role="contentinfo">
      <Link href="https://shop.plantura.garden/policies/refund-policy">
        Widerrufsrecht
      </Link>
      <Link href="https://shop.plantura.garden/policies/shipping-policy">
        Versand & Zahlung
      </Link>
      <Link href="https://shop.plantura.garden/policies/privacy-policy">
        Datenschutzerklärung
      </Link>
      <Link href="https://shop.plantura.garden/policies/legal-notice">AGB</Link>
      <Link href="https://shop.plantura.garden/policies/legal-notice">
        Impressum
      </Link>
    </StyledFooter>
  );
};

const StyledFooter = styled.footer`
  display: flex;
  background: var(--color-white);
  color: ${palette.green500};
  font-size: 0.875rem;
  font-weight: 500;
  min-height: 10rem;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: start;
  padding-block-start: 0.75rem;
  padding-inline: 1rem;
  padding-block-end: 1rem;
  line-height: 2rem;
  box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.1);

  & a {
    color: ${palette.green700};
    text-decoration: underline;
  }
  & > a + a {
    margin-inline-start: 1rem;
  }

  @media (max-width: 480px) {
    min-height: 4.5rem;
  }
`;

export default Footer;
