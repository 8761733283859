import { LoginFlow } from "@ory/client";
import { useRouter } from "next/router";
import { FormEvent, useCallback, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useApi, useToasts } from "~/hooks";
import {
  analytics,
  ButtonClickEvent,
  ScreenShownEvent,
} from "~/libs/analytics";
import { ApiError } from "~/libs/api";
import { ErrorBody } from "~/libs/types";
import { isValidEmail } from "~/libs/utils";
import {
  Box,
  Button,
  Error,
  FormField,
  Heading,
  Icon,
  InputField,
  Separator,
  Text,
} from "~/ui/components";

import { Step, StepTransition } from "./form";
import { FlowMessage } from "../components/flow-message";
import { FormIntro } from "~/ui/components/form-intro";

interface Props {
  flow: LoginFlow;
  nextStep(step: StepTransition): void;
}

const SOCIAL_LOGIN_PROVIDERS = ["apple", "google", "facebook"];

const InputEmail = ({ flow, nextStep }: Props) => {
  const form = useFormContext();
  const { t } = useTranslation();
  const router = useRouter();
  const { addToast } = useToasts();
  const { inProgress, post } = useApi<{
    data: { providers: string[] };
  }>();

  const {
    fromSplash, // means that user has clicked 'with email' to get here
  } = router.query;

  const {
    formState: { errors, touchedFields },
  } = form;

  const onSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();

      analytics.track(
        new ButtonClickEvent({
          button: "continue",
          screen: "login: email input",
        }),
      );

      const shouldPost = await form.trigger(["identifier"]);

      if (!shouldPost) return;

      try {
        const identifier = form.getValues("identifier");

        const response = await post("/api/login-options", {
          identifier,
        });

        const providers = response?.data?.data.providers || [];

        if (!providers.length) {
          router.push(`/registration?identifier=${identifier}`);
          return;
        }

        if (providers.includes("password")) {
          nextStep({
            from: Step.InputEmail,
            to: Step.InputPassword,
            payload: { email: identifier },
          });
          return;
        }

        if (providers.some((item) => SOCIAL_LOGIN_PROVIDERS.includes(item))) {
          nextStep({
            from: Step.InputEmail,
            to: Step.SelectProvider,
            payload: {
              email: identifier,
              loginProviders: providers,
            },
          });
        }
      } catch (err) {
        if (!(err instanceof ApiError)) throw err;
        const error: ErrorBody = err.response.error;

        addToast({
          title: error.title,
          description: error.detail,
        });
      }
    },
    [addToast, form, nextStep, post, router],
  );

  const screenShown = useCallback(() => {
    analytics.track(
      new ScreenShownEvent({
        screen: "login: email input",
      }),
    );
  }, []);

  useEffect(() => screenShown(), [screenShown]);

  return (
    <Box space="large">
      <form onSubmit={onSubmit} noValidate>
        <Box space="large" textCenter>
          <FormIntro
            title={t("login.default.heading")}
            intro={t("login.default.introductory")}
          />

          <FlowMessage flow={flow}></FlowMessage>

          <Box space="medium">
            <FormField>
              <InputField
                id="email"
                required
                autoFocus
                aria-required
                type="email"
                autoComplete="username"
                label={t<string>("common.email")}
                check={touchedFields?.identifier && !errors?.identifier}
                aria-invalid={!!errors?.identifier}
                exclaim={!!errors?.identifier}
                {...form.register("identifier", {
                  required: t("common.invalidEmail").toString(),
                  validate: {
                    matchPattern: (v) =>
                      isValidEmail(v) || t("common.invalidEmail").toString(),
                  },
                })}
              />
              {errors?.identifier?.message && (
                <Error id="email">{errors.identifier.message.toString()}</Error>
              )}
            </FormField>

            <Button loading={inProgress}>{t("common.continue")}</Button>
          </Box>
        </Box>
      </form>

      <Separator>{t("login.default.separator")}</Separator>

      <Box>
        <form method="POST" action={flow?.ui.action}>
          <input type="hidden" {...form.register("csrf_token")} />
          <Box space="small" className="social-login__wrapper">
            <Button
              type="button"
              variant="light"
              startIcon={<Icon icon="apple" />}>
              {t("common.loginWithApple")}
            </Button>
            <Button
              type="button"
              variant="light"
              startIcon={<Icon icon="facebook" />}>
              {t("common.loginWithFacebook")}
            </Button>
            <Button
              type="button"
              variant="light"
              startIcon={<Icon icon="google" />}>
              {t("common.loginWithGoogle")}
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export { InputEmail };
