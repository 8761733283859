import { LoginFlow } from "@ory/client";
import axios from "axios";
import Head from "next/head";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { FullScreenLoader } from "~/components";
import { AuthLayout } from "~/features/auth";
import { FlowMessage } from "~/features/auth/components";
import { useOry } from "~/hooks/use-ory";
import {
  analytics,
  ButtonClickEvent,
  ScreenShownEvent,
  trackFlow,
} from "~/libs/analytics";
import { urlForPath } from "~/libs/http";
import { handleFlowError, setFormValues } from "~/libs/ory";
import { Box, Button, Icon, Separator, Text } from "~/ui/components";
import { FormIntro } from "~/ui/components/form-intro";

type FormData = {
  csrf_token: string;
};

const LoginSplash = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const form = useForm<FormData>();
  const ory = useOry();
  const [starting, startingSet] = useState<
    "facebook" | "google" | "apple" | "email" | undefined
  >();

  const [flow, setFlow] = useState<LoginFlow>();

  const { register } = form;

  const {
    flow: flowId,
    refresh, // if set we want to refresh the session
    aal, // implies you want to perform two-factor authentication/verification.
  } = router.query;

  const trackContinueWithEmail = () => {
    startingSet("email");
    analytics.track(
      new ButtonClickEvent({
        button: "continue with email",
        screen: "home",
      }),
    );
  };

  const trackTermsOfService = () => {
    analytics.track(
      new ButtonClickEvent({
        button: "terms of service",
        screen: "home",
      }),
    );
  };

  const trackPrivacyPolicy = () => {
    analytics.track(
      new ButtonClickEvent({
        button: "privacy policy",
        screen: "home",
      }),
    );
  };

  const onSocialLogin = async (provider: "facebook" | "google" | "apple") => {
    const csrfToken = form.getValues("csrf_token");

    if (!flow || !csrfToken) return;

    startingSet(provider);

    analytics.track(
      new ButtonClickEvent({
        button: `continue with ${provider}`,
        screen: "home",
      }),
    );

    try {
      await ory.updateRegistrationFlow({
        flow: flow.id,
        updateRegistrationFlowBody: {
          csrf_token: csrfToken,
          method: "oidc",
          provider,
          upstream_parameters: {
            ...(provider === "apple" && { prompt: "login" }),
            ...(provider === "google" && { prompt: "login" }),
            ...(provider === "facebook" && { auth_type: "reauthenticate" }),
          },
        },
      });
    } catch (error) {
      if (!axios.isAxiosError(error)) throw error;
      trackFlow({ flow: error.response?.data, screen: "home" });

      await handleFlowError(router, "login")(error);
    }
  };

  useEffect(() => {
    const loginFlow = async () => {
      if (!router.isReady) {
        return;
      }

      if (flowId) {
        try {
          const { data } = await ory.getRegistrationFlow({
            id: String(flowId),
          });
          setFlow(data);
          setFormValues(form, data);
          trackFlow({ flow: data, screen: "home" });
        } catch (error) {
          if (!axios.isAxiosError(error)) throw error;
          trackFlow({ flow: error.response?.data, screen: "home" });
          await handleFlowError(router, "login")(error);
        }
        return;
      }

      try {
        const { data } = await ory.createBrowserRegistrationFlow({
          returnTo: urlForPath(`/api/redirect`),
        });

        setFlow(data);
        setFormValues(form, data);
        trackFlow({ flow: data, screen: "home" });
      } catch (error) {
        if (!axios.isAxiosError(error)) throw error;
        trackFlow({ flow: error.response?.data, screen: "home" });
        await handleFlowError(router, "login")(error);
      }
    };

    loginFlow();
  }, [aal, flowId, form, ory, refresh, router, router.isReady]);

  const screenShown = useCallback(() => {
    analytics.track(
      new ScreenShownEvent({
        screen: "home",
      }),
    );
  }, []);

  useEffect(() => screenShown(), [screenShown]);

  if (!flow) {
    return <FullScreenLoader />;
  }

  return (
    <>
      <Head>
        <title>{t("loginSplash.title")}</title>
      </Head>
      <AuthLayout>
        <Box space="medium" textCenter>
          <FormIntro
            title={t("loginSplash.heading")}
            intro={t("loginSplash.introductory")}
          />

          <FlowMessage flow={flow}></FlowMessage>

          <Separator role="heading" aria-level="2">
            {t("loginSplash.socialLogin")}
          </Separator>

          <form>
            <input type="hidden" {...register("csrf_token")} />
            <Box space="small" className="social-login__wrapper">
              <Button
                type="button"
                variant="light"
                startIcon={<Icon key="icon" icon="apple" />}
                loading={starting === "apple"}
                onClick={() => onSocialLogin("apple")}>
                {t("common.loginWithApple")}
              </Button>
              <Button
                type="button"
                variant="light"
                startIcon={<Icon key="icon" icon="facebook" />}
                loading={starting === "facebook"}
                onClick={() => onSocialLogin("facebook")}>
                {t("common.loginWithFacebook")}
              </Button>
              <Button
                type="button"
                variant="light"
                startIcon={<Icon key="icon" icon="google" />}
                loading={starting === "google"}
                onClick={() => onSocialLogin("google")}>
                {t("common.loginWithGoogle")}
              </Button>
            </Box>
          </form>

          <Separator role="heading" aria-level="2" aria-label="E-mail Login">
            {t("common.separator")}
          </Separator>

          <Link
            onClick={trackContinueWithEmail}
            tabIndex={-1}
            href={{
              pathname: "/login",
              query: { ...(refresh && { refresh }), fromSplash: true },
            }}>
            <Button loading={starting === "email"} fullWidth>
              {t("loginSplash.continueWithEmail")}
            </Button>
          </Link>

          <Text variant="light" size="small">
            {t("loginSplash.registerTerms[0]")}{" "}
            <Link
              onClick={trackTermsOfService}
              href="https://shop.plantura.garden/policies/terms-of-service"
              target="_blank">
              <Text as="span" variant="primary">
                {t("loginSplash.registerTerms[1]")}{" "}
              </Text>
            </Link>
            {t("loginSplash.registerTerms[2]")}{" "}
            <Link
              onClick={trackPrivacyPolicy}
              href="https://shop.plantura.garden/policies/privacy-policy"
              target="_blank">
              <Text as="span" variant="primary">
                {t("loginSplash.registerTerms[3]")}
              </Text>
            </Link>
          </Text>

          <Text variant="light" size="small">
            {t("loginSplash.newHere")}{" "}
            <Link href="/login">
              <Text as="span" variant="primary">
                {t("loginSplash.createAccount")}
              </Text>
            </Link>
          </Text>
        </Box>
      </AuthLayout>
    </>
  );
};

export { LoginSplash };
