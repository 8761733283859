/* eslint-disable react-hooks/exhaustive-deps */
import { VerificationFlow } from "@ory/client";
import axios from "axios";
import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect, useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { FullScreenLoader } from "~/components/full-screen-loader";
import { AuthLayout } from "~/features/auth";
import { useOry } from "~/hooks/use-ory";
import {
  analytics,
  ButtonClickEvent,
  ScreenShownEvent,
  trackFlow,
} from "~/libs/analytics";
import { urlForPath } from "~/libs/http";
import {
  handleFlowError,
  setFormValues,
  setUriFlow,
  setFormErrors,
} from "~/libs/ory";
import {
  Box,
  Button,
  Error,
  FormField,
  Heading,
  InputField,
  Separator,
  Text,
} from "~/ui/components";
import { FormIntro } from "~/ui/components/form-intro";

import { FlowMessage } from "./components/flow-message";

type FormData = {
  csrf_token: string;
  email: string;
  code: string;
  method: string;
};

const Verification = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const ory = useOry();
  const form = useForm<FormData>({ mode: "onBlur" });
  const [verificationInProgress, setVerificationInProgress] = useState(false);

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, touchedFields },
    setValue,
  } = form;

  const [flow, setFLow] = useState<VerificationFlow>();

  const { flow: flowId, return_to: returnTo } = router.query;

  const onSubmit = async (data: FormData) => {
    if (!flow) return;

    await setUriFlow(router, flow.id);

    const body = {
      csrf_token: data.csrf_token,
      flow: flow.id,
      email: data.email,
      code: data.code,
      method: data.method,
    };

    try {
      setVerificationInProgress(true);

      analytics.track(
        new ButtonClickEvent({
          button: "verify",
          screen: "verification",
        }),
      );

      const { data } = await ory.updateVerificationFlow({
        flow: flow.id,
        // @ts-ignore
        // ory/client library does not ship with correct type
        updateVerificationFlowBody: body,
      });

      setFLow(data);
      trackFlow({ flow, screen: "verification" });

      if (data.state === "passed_challenge") {
        router.push("/");
      }
    } catch (error) {
      if (!axios.isAxiosError(error)) throw error;
      trackFlow({ flow: error.response?.data, screen: "verification" });

      await handleFlowError(router, "verification")(error);

      // form validation error
      if (error.response?.status === 400) {
        setFLow(error.response.data);
        setFormErrors(form, error.response.data);
      }
    } finally {
      setVerificationInProgress(false);
    }
  };

  useEffect(() => {
    const verificationFlow = async () => {
      if (!router.isReady || flow) {
        return;
      }

      if (flowId) {
        try {
          const { data } = await ory.getVerificationFlow({
            id: String(flowId),
          });

          setFLow(data);
          setFormValues(form, data);
          trackFlow({ flow: data, screen: "verification" });
        } catch (error) {
          if (!axios.isAxiosError(error)) throw error;
          trackFlow({ flow: error.response?.data, screen: "verification" });
          await handleFlowError(router, "verification")(error);
        }
        return;
      }

      try {
        const { data } = await ory.createBrowserVerificationFlow({
          returnTo: urlForPath("/api/redirect"),
        });

        setFLow(data);
        trackFlow({ flow: data, screen: "verification" });
      } catch (error) {
        if (!axios.isAxiosError(error)) throw error;
        trackFlow({ flow: error.response?.data, screen: "verification" });
        await handleFlowError(router, "verification")(error);
      }
    };

    verificationFlow();
  }, [router, router.isReady, flow, flowId, returnTo]);

  useEffect(() => {
    if (!router.isReady) return;

    const { identifier } = router.query;

    if (!identifier || typeof identifier !== "string") {
      router.push("/registration");
      return;
    }

    setValue("email", identifier);
  }, [router.isReady]);

  const screenShown = useCallback(() => {
    analytics.track(
      new ScreenShownEvent({
        screen: "verification",
      }),
    );
  }, []);

  useEffect(() => screenShown, [screenShown]);

  if (!flow) {
    return <FullScreenLoader />;
  }

  return (
    <>
      <Head>
        <title>{t("verification.title")}</title>
      </Head>
      <AuthLayout>
        <Box space="large">
          <FormIntro title={t("verification.heading")} />

          <Separator>{t("Überprüfung deiner Identität")}</Separator>

          <FlowMessage flow={flow}></FlowMessage>

          <Box textCenter>
            <Text variant="light">
              {t("verification.intro", {
                email: getValues("email") || t("common.email"),
              })}
            </Text>
          </Box>

          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <input type="hidden" {...register("csrf_token")} />
            <input type="hidden" {...register("method")} />

            <Box space="medium">
              <FormField>
                <InputField
                  autoComplete="username"
                  label={t<string>("common.email")}
                  readOnly
                  {...register("email")}
                />
                {errors.email && <Error>{errors.email.message}</Error>}
              </FormField>
              <FormField>
                <InputField
                  id="code"
                  required
                  autoFocus
                  aria-required
                  autoComplete="one-time-code"
                  label={t<string>("common.code")}
                  check={touchedFields?.code && !errors?.code}
                  aria-describedby={errors?.code && "code-error"}
                  aria-invalid={!!errors?.code}
                  exclaim={!!errors?.code}
                  {...register("code", {
                    required: t(
                      "verification.validation.code.required",
                    ).toString(),
                  })}
                />
                {errors.code && (
                  <Error id="code-error">{errors.code.message}</Error>
                )}
              </FormField>
              <Button disabled={verificationInProgress}>
                {t("verification.activate")}
              </Button>
            </Box>
          </form>
        </Box>
      </AuthLayout>
    </>
  );
};

export { Verification };
